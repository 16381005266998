import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

// Resources: 
// https://www.wesleylhandy.net/blog/seo-accessibility-first-gatsby.html
// https://www.gatsbyjs.com/docs/add-seo-component/
// https://blog.dustinschau.com/search-engine-optimization-with-gatsby

// https://seositecheckup.seofrequency.com/
// https://seositecheckup.com/

// https://www.pcmag.com/picks/the-best-seo-tools

const SEO = ({ title, description, keywords, image, canonical, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    siteUrl,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    defaultKeywords,
    defaultUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    image: `${defaultUrl}${image || defaultImage}`,
    url: `${defaultUrl}${pathname}`,
    canonical: canonical,
    siteUrl: siteUrl
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate} htmlAttributes={{lang: "en-us"}} defer={false}>

      <meta http-equiv="refresh" content="5; url=https://localchefs.us/" />

      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keywords" content={seo.keywords} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      <meta property="og:type" content="article" />

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {seo.siteUrl && seo.canonical && <link rel="canonical" href={seo.siteUrl+seo.canonical} />}

      <link rel="manifest" href="/site.webmanifest"></link>
      <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon32.png"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon16.png"/>
      
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  url: null,
  keywords: null,
  image: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        defaultKeywords: keywords
        defaultUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`