import React from 'react';

/* For footer fixed at the bottom of the screen: <footer className="bg-primary lg:fixed lg:left-0 lg:bottom-0 lg:right-0 z-40"> */

const Footer = () => (
  <footer className="bg-primary">
    <div className="container px-5 py-4 mx-auto">
      <div className="flex flex-wrap md:text-left text-center order-first">
        <div className="md:w-1/3 w-full">
          <h2 className="title-font font-bold text-white tracking-widest text-sm mb-3">COPYRIGHT © 2021 INFISOLV INC.</h2>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
