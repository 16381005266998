import React from 'react';
import logoImg from "../../images/logo.png"; 

const Header = () => {
  return (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-row justify-between items-start sm:items-center mx-auto py-4 px-4 sm:px-8">
      <div className="flex items-start sm:items-center text-2xl">
        <div className="w-12 mr-3">
          <div className=" h-8 w-12 mt-2 flex flex-row">
            <img src={logoImg} width="48" height="32" alt="HomeCatered Logo" />
            <p className="ml-1 text-primary">HOMECATERED</p>
          </div>
        </div>
      </div>
      <div className="hidden md:flex text-lg md:text-2xl text-primary">Find Unique, Specialty Home Food Near You</div>
    </div>

    <div className="container flex flex-row justify-between items-start sm:items-center mx-auto sm:px-8 px-4 text-primary text-sm sm:text-lg -mt-4 pb-1">
      Sustainable, Healthy, Delicious Home Food
    </div>
  </header>
);}

export default Header;
