import React from 'react';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/Seo';
import { Broken } from '../svg/icons';


const Page_404 = () => (
  <Layout>
    <SEO /> 
    <section className="py-5 md:py-10 bg-primary uppercase">
      <h1 className='text-lg sm:text-2xl lg:text-4xl text-white text-center'>
      Page Not Found
      </h1>
    </section>

    <section className="pt-5 md:pt-10">
      <div className="grid container mx-auto px-2 py-2 sm:px-8 sm:py-4 flex" >

        <Broken className="text-primary justify-self-center h-16 w-16" />
        <h2 className="text-2xl lg:text-4xl font-semibold text-primary text-center">
          Error 404
        </h2>
        <div className="justify-self-center mt-12 mb-4">
        <p className="text-lg sm:text-xl lg:text-2xl mb-4">
          Hmm... 
        </p>
        <p className="sm:text-lg lg:text-xl mb-4">
          It seems like we did not find what you were looking for.
        </p>
        <p className="sm:text-lg lg:text-xl mb-4">
          The link is broken or the page has been moved.
        </p>
        <p className="sm:text-lg lg:text-xl mb-4">
          Please Try one of these pages instead:
        </p>
        </div>
        <div className="flex flex-col items-center">
          <div className="mr-5 sm:text-lg lg:text-xl"><a href="/">Home</a></div>
          <div className="mr-5 sm:text-lg lg:text-xl"><a href="/blog/">Blog</a></div>
          <div className="mr-5 sm:text-lg lg:text-xl" ><a href="/catering-service/">App for Catering</a></div>
          <div className="mr-5 sm:text-lg lg:text-xl"><a href="/awesome-home-made-food-catered-to-you/">App for User</a></div>
        </div>

      </div>
    </section>


    {/* Workaround for footer encroaching on the bottom part of the SplitSection above. WHY? */}
    <section className="pt-60">
      <div className="grid container mx-auto px-2 py-2 sm:px-4 sm:py-4 flex" >
        &nbsp; 
      </div>
    </section>

  </Layout>
);

export default Page_404;