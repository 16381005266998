import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="text-gray-900">{children}</main>

      {/* To avoid footer encroaching on the bottom part of the SplitSection above. */}
      <div className="py-4">
          &nbsp;
      </div>

      <Footer />
    </>
  );
};

export default Layout;
