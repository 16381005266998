import React from 'react';

export const AppleLogo = ({className = ''}) => (
  <svg 
    className={`
      ${className}
    `}
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 22.773 22.773"
  >
    <g>
    <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573
      c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z"/>
    <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334
      c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0
      c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019
      c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464
      c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648
      c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z"/>
    </g>
  </svg>
);


export const GoogleLogo = ({className = ''}) => (
  <svg 
    className={`
      ${className}
    `}
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 262 262"
  >
    <path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"/>
    <path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"/>
    <path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"/>
    <path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EB4335"/>
  </svg>
);


export const GoogleLogoMono = ({className = ''}) => (
  <svg 
    className={`
      ${className}
    `}
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 210 210"
  >
    <path d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40
    c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105
    S0,162.897,0,105z"/>
  </svg>
);


export const ChevronDown = ({className = ''}) => (
  <svg 
    className={`
      ${className}
    `}
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 1792 1792"
  >
    <path d="M1683 1331l-166 165q-19 19-45 19t-45-19L896 965l-531 531q-19 19-45 19t-45-19l-166-165q-19-19-19-45.5t19-45.5l742-741q19-19 45-19t45 19l742 741q19 19 19 45.5t-19 45.5z" />
  </svg>
);


export const ChevronUp = ({className = ''}) => (
  <svg 
    className={`
      ${className}
    `}
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 1792 1792"
  >
    <path d="M1683 808l-742 741q-19 19-45 19t-45-19L109 808q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
  </svg>
);

export const Star = ({className = ''}) => (
  <svg className={`${className}`} fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"  viewBox="0 0 24 24">
    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
  </svg>
)
export const StarO = ({className = ''}) => (
  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
  className={`${className}`} >
    <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"></path>
  </svg>
)

export const Facebook = ({className = ''}) => (
  <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"  viewBox="0 0 24 24"
  className={`${className}`} >
    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
  </svg>
)

export const Twitter = ({className = ''}) => (
  <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"  viewBox="0 0 24 24"
  className={`${className}`} >
    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
  </svg>
)

export const Instagram = ({className = ''}) => (
  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
  className={`${className}`} >
    <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
  </svg>
)

export const LinkedIn = ({className = ''}) => (
  <svg fill="currentColor" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0" viewBox="0 0 24 24"
  className={`${className}`} >
    <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
    <circle cx="4" cy="4" r="2" stroke="none"></circle>
  </svg>
)

export const Chat = ({className = ''}) => (
  <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"  viewBox="0 0 24 24"
  className={`${className}`} >
    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
  </svg>
)

export const ExternalLink = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 1792 1792"
  className={`${className}`} >
    <path d="M1408 928v320q0 119-84.5 203.5T1120 1536H288q-119 0-203.5-84.5T0 1248V416q0-119 84.5-203.5T288 128h704q14 0 23 9t9 23v64q0 14-9 23t-23 9H288q-66 0-113 47t-47 113v832q0 66 47 113t113 47h832q66 0 113-47t47-113V928q0-14 9-23t23-9h64q14 0 23 9t9 23zm384-864v512q0 26-19 45t-45 19-45-19l-176-176-652 652q-10 10-23 10t-23-10L695 983q-10-10-10-23t10-23l652-652-176-176q-19-19-19-45t19-45 45-19h512q26 0 45 19t19 45z"/>
  </svg>
)

export const Caution = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 512 512"
  className={`${className}`} >
    <path
      d="M503.191 381.957c-.055-.096-.111-.19-.168-.286L312.267 63.218l-.059-.098c-9.104-15.01-23.51-25.577-40.561-29.752-17.053-4.178-34.709-1.461-49.72 7.644a66 66 0 0 0-22.108 22.109l-.058.097L9.004 381.669c-.057.096-.113.191-.168.287-8.779 15.203-11.112 32.915-6.569 49.872 4.543 16.958 15.416 31.131 30.62 39.91a65.88 65.88 0 0 0 32.143 8.804l.228.001h381.513l.227.001c36.237-.399 65.395-30.205 64.997-66.444a65.86 65.86 0 0 0-8.804-32.143zm-56.552 57.224H65.389a24.397 24.397 0 0 1-11.82-3.263c-5.635-3.253-9.665-8.507-11.349-14.792a24.196 24.196 0 0 1 2.365-18.364L235.211 84.53a24.453 24.453 0 0 1 8.169-8.154c5.564-3.374 12.11-4.381 18.429-2.833 6.305 1.544 11.633 5.444 15.009 10.986L467.44 402.76a24.402 24.402 0 0 1 3.194 11.793c.149 13.401-10.608 24.428-23.995 24.628z" />
    <path
      d="M256.013 168.924c-11.422 0-20.681 9.26-20.681 20.681v90.085c0 11.423 9.26 20.681 20.681 20.681 11.423 0 20.681-9.259 20.681-20.681v-90.085c.001-11.421-9.258-20.681-20.681-20.681zM270.635 355.151c-3.843-3.851-9.173-6.057-14.624-6.057a20.831 20.831 0 0 0-14.624 6.057c-3.842 3.851-6.057 9.182-6.057 14.624 0 5.452 2.215 10.774 6.057 14.624a20.822 20.822 0 0 0 14.624 6.057c5.45 0 10.772-2.206 14.624-6.057a20.806 20.806 0 0 0 6.057-14.624 20.83 20.83 0 0 0-6.057-14.624z" />
  </svg>
)
export const Good = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 512 512"
  className={`${className}`} >
    <path
      d="M468.907 214.604c-11.423 0-20.682 9.26-20.682 20.682v20.831c-.031 54.338-21.221 105.412-59.666 143.812-38.417 38.372-89.467 59.5-143.761 59.5h-.12C132.506 459.365 41.3 368.056 41.364 255.883c.031-54.337 21.221-105.411 59.667-143.813 38.417-38.372 89.468-59.5 143.761-59.5h.12c28.672.016 56.49 5.942 82.68 17.611 10.436 4.65 22.659-.041 27.309-10.474 4.648-10.433-.04-22.659-10.474-27.309-31.516-14.043-64.989-21.173-99.492-21.192h-.144c-65.329 0-126.767 25.428-172.993 71.6C25.536 129.014.038 190.473 0 255.861c-.037 65.386 25.389 126.874 71.599 173.136 46.21 46.262 107.668 71.76 173.055 71.798h.144c65.329 0 126.767-25.427 172.993-71.6 46.262-46.209 71.76-107.668 71.798-173.066v-20.842c0-11.423-9.259-20.683-20.682-20.683z" />
    <path
      d="M505.942 39.803c-8.077-8.076-21.172-8.076-29.249 0L244.794 271.701l-52.609-52.609c-8.076-8.077-21.172-8.077-29.248 0-8.077 8.077-8.077 21.172 0 29.249l67.234 67.234a20.616 20.616 0 0 0 14.625 6.058 20.618 20.618 0 0 0 14.625-6.058L505.942 69.052c8.077-8.077 8.077-21.172 0-29.249z" />
  </svg>
)
export const Info = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 512 512"
  className={`${className}`} >
    <path
      d="M437.019 74.981C388.667 26.629 324.38 0 256 0S123.333 26.63 74.981 74.981 0 187.62 0 256s26.629 132.667 74.981 181.019C123.332 485.371 187.62 512 256 512s132.667-26.629 181.019-74.981C485.371 388.667 512 324.38 512 256s-26.629-132.668-74.981-181.019zM256 470.636C137.65 470.636 41.364 374.35 41.364 256S137.65 41.364 256 41.364 470.636 137.65 470.636 256 374.35 470.636 256 470.636z" />
    <path
      d="M256 235.318c-11.422 0-20.682 9.26-20.682 20.682v94.127c0 11.423 9.26 20.682 20.682 20.682 11.423 0 20.682-9.259 20.682-20.682V256c0-11.422-9.259-20.682-20.682-20.682zM270.625 147.248A20.826 20.826 0 0 0 256 141.19a20.826 20.826 0 0 0-14.625 6.058 20.824 20.824 0 0 0-6.058 14.625 20.826 20.826 0 0 0 6.058 14.625A20.83 20.83 0 0 0 256 182.556a20.826 20.826 0 0 0 14.625-6.058 20.826 20.826 0 0 0 6.058-14.625 20.839 20.839 0 0 0-6.058-14.625z" />
  </svg>
)
export const Bad = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 512 512"
  className={`${className}`} >
    <path
      d="M437.019 74.981C388.667 26.629 324.38 0 256 0S123.333 26.63 74.981 74.981 0 187.62 0 256s26.629 132.667 74.981 181.019C123.332 485.371 187.62 512 256 512s132.667-26.629 181.019-74.981C485.371 388.667 512 324.38 512 256s-26.629-132.668-74.981-181.019zM256 470.636C137.65 470.636 41.364 374.35 41.364 256S137.65 41.364 256 41.364 470.636 137.65 470.636 256 374.35 470.636 256 470.636z"
      fill="#FFF" />
    <path
      d="M341.22 170.781c-8.077-8.077-21.172-8.077-29.249 0L170.78 311.971c-8.077 8.077-8.077 21.172 0 29.249 4.038 4.039 9.332 6.058 14.625 6.058s10.587-2.019 14.625-6.058l141.19-141.191c8.076-8.076 8.076-21.171 0-29.248z"
      fill="#FFF" />
    <path
      d="M341.22 311.971l-141.191-141.19c-8.076-8.077-21.172-8.077-29.248 0-8.077 8.076-8.077 21.171 0 29.248l141.19 141.191a20.616 20.616 0 0 0 14.625 6.058 20.618 20.618 0 0 0 14.625-6.058c8.075-8.077 8.075-21.172-.001-29.249z"
      fill="#FFF" />
  </svg>
)
export const Hamburger = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 48 48"
  className={`${className}`} >
    <path d="M41,14H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,14Z" />
    <path d="M41,26H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,26Z" />
    <path d="M41,38H7a2,2,0,0,1,0-4H41A2,2,0,0,1,41,38Z" />
  </svg>
)
export const Broken = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 512 512"
  className={`${className}`} >
		<path d="M362.087,164.017H149.915c-6.62,0-11.988,5.367-11.988,11.988c0,6.621,5.367,11.988,11.988,11.988h212.172
			c6.621,0,11.988-5.368,11.988-11.988C374.075,169.384,368.708,164.017,362.087,164.017z"/>
		<path d="M362.087,236.355H149.915c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.367,11.988,11.988,11.988h212.172
			c6.621,0,11.988-5.368,11.988-11.988S368.708,236.355,362.087,236.355z"/>
		<path d="M447.937,103.439c-0.082-3.008-1.276-5.944-3.489-8.158l-91.69-91.69c-2.176-2.214-5.2-3.592-8.55-3.592H89.285
			c-13.92,0-25.247,11.325-25.247,25.248l0.005,299.353c0,0.106,0.013,0.207,0.016,0.313c0.164,6.065,3.303,11.562,8.484,14.779
			l41.352,25.667c13.649,8.469,30.605,8.469,44.252-0.001l28.381-17.614c5.849-3.631,13.116-3.63,18.965-0.001l28.381,17.615
			c6.824,4.237,14.474,6.355,22.126,6.354c7.651,0,15.304-2.118,22.126-6.354l28.381-17.615c5.849-3.629,13.116-3.629,18.965,0
			l28.381,17.615c13.645,8.472,30.6,8.472,44.252,0l41.355-25.667c5.179-3.215,8.317-8.713,8.482-14.777
			c0.002-0.104,0.016-0.207,0.016-0.313l0.005-220.845C447.964,103.64,447.946,103.543,447.937,103.439z M356.193,40.935h0.001
			l50.834,50.834h-49.573c-0.695,0-1.262-0.567-1.262-1.262V40.935z M423.983,321.08l-38.52,23.908
			c-5.849,3.631-13.116,3.63-18.965,0.001l-28.381-17.616c-13.649-8.469-30.605-8.468-44.252,0.001l-28.381,17.615
			c-5.849,3.629-13.116,3.629-18.965,0l-28.381-17.615c-13.646-8.472-30.602-8.472-44.252,0l-28.381,17.615
			c-5.849,3.629-13.116,3.629-18.965,0l-38.52-23.909L88.014,25.248c0-0.701,0.571-1.272,1.271-1.272h242.933v66.532
			c0,13.916,11.321,25.238,25.238,25.238h66.532L423.983,321.08z"/>
		<path d="M447.958,395.948c0.001-0.031-0.004-0.061-0.004-0.092s0.005-0.061,0.005-0.092c0-6.521-3.541-12.544-9.242-15.714
			c-5.7-3.168-12.682-3.003-18.223,0.436l-35.44,21.994c-5.598,3.473-12.554,3.473-18.15,0.001l-29.197-18.121
			c-13.397-8.317-30.042-8.317-43.437,0l-29.197,18.12c-5.598,3.473-12.553,3.473-18.151,0.001l-29.197-18.121
			c-13.396-8.315-30.042-8.315-43.437,0l-29.195,18.12c-5.598,3.473-12.554,3.474-18.15,0l-35.439-21.994
			c-5.541-3.438-12.523-3.608-18.224-0.436c-5.7,3.171-9.242,9.192-9.242,15.714c0,0.031,0.005,0.061,0.005,0.092
			c0,0.031-0.005,0.061-0.005,0.092l-0.005,90.804c0,13.922,11.325,25.248,25.247,25.248h333.434
			c13.92,0,25.247-11.325,25.247-25.249L447.958,395.948z M422.717,488.024H89.285c-0.7,0-1.271-0.571-1.271-1.271l0.004-80.215
			l26.285,16.314c13.398,8.312,30.042,8.312,43.436-0.001l29.195-18.121c5.597-3.474,12.551-3.473,18.151,0l29.195,18.122
			c6.699,4.156,14.209,6.235,21.719,6.235c7.509,0,15.02-2.079,21.719-6.236l29.195-18.121c5.597-3.474,12.551-3.475,18.151,0
			l29.197,18.122c13.4,8.314,30.042,8.312,43.436,0l26.285-16.314l0.004,80.214C423.988,487.453,423.418,488.024,422.717,488.024z"
			/>
  </svg>
)
export const Pasta = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 59.481 59.481"
    className={`${className}`} >
    <path d="M25.96,38.114c0.525-0.755,0.895-1.631,1.048-2.59l0.991-3.588l-3.588,0.991c-0.958,0.152-1.832,0.522-2.587,1.046
      c-0.129-1.015-0.501-2.008-1.136-2.884l-1.836-3.238l-1.836,3.238c-1.596,2.199-1.597,5.128,0,7.327l1.432,2.525l0.544,0.546
      l2.531,1.435c1.1,0.798,2.382,1.196,3.663,1.196c1.282,0,2.564-0.398,3.664-1.196l3.237-1.836l-3.237-1.837
      C27.972,38.614,26.978,38.243,25.96,38.114z M24.769,34.896l0.363-0.093l-0.093,0.363c-0.276,1.858-1.711,3.294-3.569,3.57
      l-0.364,0.093l0.093-0.364C21.475,36.607,22.91,35.173,24.769,34.896z M18.66,32.229l0.191-0.323l0.191,0.323
      c1.119,1.51,1.119,3.538,0,5.048L18.851,37.6l-0.191-0.323C17.541,35.768,17.541,33.739,18.66,32.229z M27.711,41.279
      c-1.512,1.118-3.542,1.116-5.05,0l-0.323-0.191l0.323-0.192c1.509-1.117,3.539-1.117,5.05,0l0.323,0.192L27.711,41.279z
      M20.944,30.405c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l3.509-3.509c0.391-0.391,0.391-1.023,0-1.414
      s-1.023-0.391-1.414,0l-3.509,3.509C20.553,29.382,20.553,30.015,20.944,30.405z M24.289,31.303
      c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l4.386-4.387c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0
      l-4.386,4.387C23.899,30.279,23.899,30.912,24.289,31.303z M33.26,29.402l-4.387,4.387c-0.391,0.391-0.391,1.023,0,1.414
      c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l4.387-4.387c0.391-0.391,0.391-1.023,0-1.414
      S33.651,29.012,33.26,29.402z M28.937,30.88c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l3.948-3.947
      c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-3.948,3.947C28.546,29.856,28.546,30.489,28.937,30.88z M32.938,33.968
      l-3.509,3.509c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l3.509-3.509
      c0.391-0.391,0.391-1.023,0-1.414S33.328,33.577,32.938,33.968z M8.332,40.189l1.414-1.414l1.414,1.414l-1.414,1.414L8.332,40.189z
      M10.455,42.312l1.414-1.415l1.415,1.414l-1.414,1.415L10.455,42.312z M12.574,44.431l1.414-1.414l1.414,1.414l-1.414,1.414
      L12.574,44.431z M14.695,46.553l1.414-1.414l1.414,1.414l-1.414,1.414L14.695,46.553z M16.817,48.674l1.414-1.414l1.414,1.414
      l-1.414,1.414L16.817,48.674z M20.352,52.21l-1.414-1.414l1.414-1.414l1.414,1.414L20.352,52.21z M24.795,14.81
      c-1.225,2.448-2.827,4.672-4.762,6.606L5.987,35.464l-5.541,2.523l-0.067,0.558c-0.148,1.239,0.373,2.632,1.431,3.822
      c0.914,1.028,2.057,1.7,3.146,1.869c0.104,0.942,0.497,1.79,1.134,2.427c0.633,0.632,1.475,1.023,2.411,1.129
      c0.105,0.938,0.497,1.779,1.131,2.412c0.633,0.634,1.475,1.025,2.412,1.131c0.105,0.938,0.497,1.779,1.13,2.413
      c0.637,0.636,1.483,1.028,2.426,1.132c0.168,1.088,0.841,2.23,1.869,3.146c1.057,0.938,2.273,1.455,3.4,1.455
      c0.143,0,0.283-0.008,0.422-0.024l0.56-0.067l2.493-5.512L38.42,39.802c1.936-1.936,4.158-3.537,6.606-4.761l3.063-1.531
      L26.325,11.746L24.795,14.81z M26.874,15.123L44.711,32.96l-0.581,0.291c-2.641,1.32-5.038,3.048-7.126,5.136L23.181,52.21
      l-0.707-0.707l-1.414,1.414l1.048,1.048l-1.576,3.484c-0.554-0.099-1.18-0.425-1.735-0.919c-0.755-0.67-1.241-1.526-1.24-2.178
      c0.002-0.377-0.153-0.749-0.426-1.021c-0.271-0.27-0.638-0.424-1.01-0.424c-0.006-0.001-0.012-0.001-0.017,0
      c-0.617,0-1.142-0.198-1.517-0.573c-0.378-0.378-0.576-0.906-0.574-1.528c0.002-0.394-0.146-0.758-0.415-1.027
      c-0.267-0.267-0.629-0.414-1.02-0.414c-0.006-0.001-0.012-0.001-0.017,0c-0.617,0-1.142-0.198-1.518-0.574
      c-0.378-0.378-0.576-0.906-0.574-1.528c0.002-0.393-0.146-0.757-0.414-1.025s-0.63-0.415-1.021-0.415c-0.002,0-0.004,0-0.006,0
      c-0.637,0.033-1.148-0.196-1.527-0.573c-0.378-0.378-0.576-0.906-0.574-1.528c0.002-0.374-0.153-0.744-0.424-1.016
      c-0.272-0.272-0.624-0.415-1.02-0.426c0,0-0.001,0-0.002,0c-0.653,0-1.508-0.486-2.178-1.24c-0.495-0.557-0.821-1.183-0.919-1.736
      l4.759-2.168L6.21,38.067l1.414,1.414l1.415-1.414l-1.414-1.414l-0.043,0.043L21.447,22.83c2.087-2.087,3.815-4.484,5.137-7.126
      L26.874,15.123z M40.656,1.153l1.393,1.436L30.126,14.155l-1.393-1.436L40.656,1.153z M47.128,31.097l-1.451-1.377l10.949-11.54
      l1.451,1.377L47.128,31.097z M47.106,1.414l-14.85,14.85l-1.414-1.414L45.692,0L47.106,1.414z M51.35,1.414l-16.97,16.97
      l-1.414-1.414L49.935,0L51.35,1.414z M54.177,2.828L36.5,20.506l-1.414-1.414L52.763,1.414L54.177,2.828z M56.299,4.949
      L38.622,22.627l-1.414-1.414L54.885,3.535L56.299,4.949z M40.743,24.749l-1.414-1.414l16.97-16.97l1.414,1.414L40.743,24.749z
      M42.864,26.871l-1.414-1.414L57.713,9.194l1.414,1.414L42.864,26.871z M45,28.985l-1.432-1.397l14.069-14.421l1.432,1.397
      L45,28.985z"/>
  </svg>
)

export const Food = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 60 60"
    className={`${className}`} >
      <path d="M18.35,20.805c0.195,0.195,0.451,0.293,0.707,0.293c0.256,0,0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414
        c-1.015-1.016-1.015-2.668,0-3.684c0.87-0.87,1.35-2.026,1.35-3.256s-0.479-2.386-1.35-3.256c-0.391-0.391-1.023-0.391-1.414,0
        s-0.391,1.023,0,1.414c0.492,0.492,0.764,1.146,0.764,1.842s-0.271,1.35-0.764,1.842C16.555,16.088,16.555,19.01,18.35,20.805z"/>
      <path d="M40.35,20.805c0.195,0.195,0.451,0.293,0.707,0.293c0.256,0,0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414
        c-1.015-1.016-1.015-2.668,0-3.684c0.87-0.87,1.35-2.026,1.35-3.256s-0.479-2.386-1.35-3.256c-0.391-0.391-1.023-0.391-1.414,0
        s-0.391,1.023,0,1.414c0.492,0.492,0.764,1.146,0.764,1.842s-0.271,1.35-0.764,1.842C38.555,16.088,38.555,19.01,40.35,20.805z"/>
      <path d="M29.35,14.805c0.195,0.195,0.451,0.293,0.707,0.293c0.256,0,0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414
        c-1.015-1.016-1.015-2.668,0-3.684c0.87-0.87,1.35-2.026,1.35-3.256s-0.479-2.386-1.35-3.256c-0.391-0.391-1.023-0.391-1.414,0
        s-0.391,1.023,0,1.414c0.492,0.492,0.764,1.146,0.764,1.842s-0.271,1.35-0.764,1.842C27.555,10.088,27.555,13.01,29.35,14.805z"/>
      <path d="M25.345,28.61c0.073,0,0.147-0.008,0.221-0.024c1.438-0.324,2.925-0.488,4.421-0.488c0.004,0,0.008,0,0.013,0h0
        c0.552,0,1-0.447,1-0.999c0-0.553-0.447-1.001-1-1.001c-0.004,0-0.009,0-0.014,0c-1.643,0-3.278,0.181-4.86,0.537
        c-0.539,0.121-0.877,0.656-0.756,1.195C24.476,28.295,24.888,28.61,25.345,28.61z"/>
      <path d="M9.821,45.081c0.061,0.012,0.121,0.017,0.18,0.017c0.474,0,0.895-0.338,0.983-0.82c1.039-5.698,4.473-10.768,9.186-13.56
        c0.475-0.281,0.632-0.895,0.351-1.37c-0.282-0.475-0.895-0.632-1.37-0.351c-5.204,3.083-8.992,8.661-10.134,14.921
        C8.917,44.462,9.277,44.982,9.821,45.081z"/>
      <path d="M55.624,43.721C53.812,33.08,45.517,24.625,34.957,22.577c0.017-0.16,0.043-0.321,0.043-0.48c0-2.757-2.243-5-5-5
        s-5,2.243-5,5c0,0.159,0.025,0.32,0.043,0.48C14.483,24.625,6.188,33.08,4.376,43.721C2.286,44.904,0,46.645,0,48.598
        c0,5.085,15.512,8.5,30,8.5s30-3.415,30-8.5C60,46.645,57.714,44.904,55.624,43.721z M27.006,22.27
        C27.002,22.212,27,22.154,27,22.098c0-1.654,1.346-3,3-3s3,1.346,3,3c0,0.057-0.002,0.114-0.006,0.172
        c-0.047-0.005-0.094-0.007-0.14-0.012c-0.344-0.038-0.69-0.065-1.038-0.089c-0.128-0.009-0.255-0.022-0.383-0.029
        c-0.474-0.026-0.951-0.041-1.432-0.041s-0.958,0.015-1.432,0.041c-0.128,0.007-0.255,0.02-0.383,0.029
        c-0.348,0.024-0.694,0.052-1.038,0.089C27.1,22.263,27.053,22.264,27.006,22.27z M26.399,24.368
        c0.537-0.08,1.077-0.138,1.619-0.182c0.111-0.009,0.222-0.017,0.333-0.025c1.098-0.074,2.201-0.074,3.299,0
        c0.111,0.008,0.222,0.016,0.333,0.025c0.542,0.044,1.082,0.102,1.619,0.182c10.418,1.575,18.657,9.872,20.152,20.316
        c0.046,0.321,0.083,0.643,0.116,0.965c0.011,0.111,0.026,0.221,0.036,0.332c0.039,0.443,0.068,0.886,0.082,1.329
        c-15.71,3.641-32.264,3.641-47.974,0c0.015-0.443,0.043-0.886,0.082-1.329c0.01-0.111,0.024-0.221,0.036-0.332
        c0.033-0.323,0.07-0.645,0.116-0.965C7.742,34.24,15.981,25.942,26.399,24.368z M30,55.098c-17.096,0-28-4.269-28-6.5
        c0-0.383,0.474-1.227,2.064-2.328c-0.004,0.057-0.002,0.113-0.006,0.17C4.024,46.988,4,47.54,4,48.098v0.788l0.767,0.185
        c8.254,1.98,16.744,2.972,25.233,2.972s16.979-0.991,25.233-2.972L56,48.886v-0.788c0-0.558-0.024-1.109-0.058-1.658
        c-0.004-0.057-0.002-0.113-0.006-0.17C57.526,47.371,58,48.215,58,48.598C58,50.829,47.096,55.098,30,55.098z"/>
  </svg>
)

export const Book = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 512 512"
    className={`${className}`} >
    <path d="m443.6,11h-321c-35.4,0-64.6,26.1-64.6,59.4v371.1c0,33.4 26.1,59.4 59.4,59.4h326.2c6.3,0 10.4-4.2 10.4-11.5v-468c0-6.2-4.2-10.4-10.4-10.4zm-321,20.9h310.5v350.3h-315.7c-14.9,0-28.3,5.2-38.6,13.9v-325.7c-1.42109e-14-21.9 19.8-38.5 43.8-38.5zm310.6,449.3h-315.8c-21.9,0-38.6-16.7-38.6-38.6 0-21.9 17.7-38.6 38.6-38.6l315.8,.1v77.1z"/>
  </svg>
)
  
export const Home = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 58.365 58.365" 
    className={`${className}`} >
      <path d="M57.863,26.632l-8.681-8.061V5.365h-10v3.921L29.182,0L0.502,26.632c-0.404,0.376-0.428,1.009-0.052,1.414
      c0.375,0.404,1.008,0.427,1.414,0.052l3.319-3.082v33.349h16h16h16V25.015l3.319,3.082c0.192,0.179,0.437,0.267,0.681,0.267
      c0.269,0,0.536-0.107,0.732-0.319C58.291,27.641,58.267,27.008,57.863,26.632z M41.182,7.365h6v9.349l-6-5.571V7.365z
      M23.182,56.365V35.302c0-0.517,0.42-0.937,0.937-0.937h10.126c0.517,0,0.937,0.42,0.937,0.937v21.063H23.182z M51.182,56.365h-14
      V35.302c0-1.62-1.317-2.937-2.937-2.937H24.119c-1.62,0-2.937,1.317-2.937,2.937v21.063h-14V23.158l22-20.429l14.28,13.26
      l5.72,5.311v0l2,1.857V56.365z"/>
  </svg>
)

export const Valet = ({className = ''}) => (
  <svg fill="currentColor" viewBox="0 0 30.711 30.711"
    className={`${className}`} >
    <path d="M19.008,18.814c1.055-1.071,1.836-2.51,2.301-3.886c0.637-0.262,1.156-0.912,1.354-1.701
    c0.182-0.727,0.608-2.43-0.328-2.955V9.055c0.063-2.158-0.187-4.777-2.664-5.848c-0.197-0.52-0.238-0.977-0.244-1.064L19.215,0
    l-1.523,1.436c-0.498,0.469-2.792,0.922-4.262,1.068l-0.7,0.109c-4.5,0.707-4.437,2.99-4.369,5.412
    C8.369,8.34,8.378,8.672,8.378,9.027l0.033,1.1c-0.673,0.445-0.829,1.5-0.449,3.057c0.193,0.775,0.74,1.43,1.334,1.609
    c0.286,0.793,1.06,2.641,2.407,4.021c-5.605,1.414-9.727,5.98-9.727,11.396v0.5h26.758v-0.5
    C28.736,24.797,24.613,20.229,19.008,18.814z M22.24,29.713v-2.615c0-0.277-0.223-0.5-0.5-0.5c-0.275,0-0.5,0.223-0.5,0.5v2.615
    H9.474v-2.615c0-0.277-0.224-0.5-0.5-0.5c-0.276,0-0.5,0.223-0.5,0.5v2.615H2.992c0.194-3.74,2.563-6.984,6.015-8.805l5.983,6.795
    c0.095,0.105,0.231,0.168,0.375,0.168c0.001,0,0.002,0,0.003,0c0.146,0,0.282-0.064,0.376-0.174l5.856-6.846
    c3.51,1.809,5.926,5.08,6.123,8.858H22.24V29.713z M14.287,20.368l-0.662,0.565l1.083,0.932l-0.79,3.105l-3.974-4.512
    c0.855-0.367,1.763-0.65,2.713-0.838C13.144,19.961,13.686,20.221,14.287,20.368z M18.051,19.624
    c0.912,0.178,1.783,0.446,2.606,0.791l-3.977,4.646l-0.812-3.195l1.083-0.932l-0.63-0.541
    C16.947,20.256,17.523,19.986,18.051,19.624z M10.12,14.014c-0.384,0.082-0.982-0.377-1.157-1.08
    c-0.18-0.715-0.298-1.871,0.092-1.979c0.115-0.033,0.235-0.008,0.355,0.055V9.024c0-3.125-0.705-4.959,4.173-5.481L13.54,3.528
    c0,0,3.847-0.387,4.859-1.342c0,0,0.027,0.805,0.439,1.689c2.25,0.904,2.533,2.988,2.469,5.148v1.987
    c0.117-0.063,0.238-0.09,0.354-0.058c0.389,0.107,0.182,1.31,0.002,2.023c-0.175,0.695-0.677,1.109-1.06,1.037
    c-0.496,1.51-1.474,3.423-2.858,4.54c-0.195,0.156-0.396,0.3-0.607,0.421c-0.33,0.19-0.681,0.327-1.049,0.411
    c-0.237,0.054-0.479,0.089-0.73,0.089c-0.256,0-0.5-0.035-0.74-0.089c-0.372-0.082-0.725-0.219-1.057-0.407
    c-0.212-0.123-0.415-0.267-0.61-0.422C11.575,17.454,10.617,15.559,10.12,14.014z"/>
  </svg>
)

export const RightArrow = ({className = ''}) => (
  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className={`${className}`} viewBox="0 0 24 24">
    <path d="M5 12h14M12 5l7 7-7 7"></path>
  </svg>)

export const Article = ({className = ''}) => (
  <svg fill="currentColor" className={`${className}`} viewBox="0 0 465 465">
    <path d="M405.437,0h-10c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h2.5v435h-2.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5
      h10c4.143,0,7.5-3.358,7.5-7.5V7.5C412.937,3.358,409.579,0,405.437,0z"/>
    <path d="M315.437,140H109.563c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h205.873c4.143,0,7.5-3.358,7.5-7.5 S319.579,140,315.437,140z"/>
    <path d="M315.437,260H109.563c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h205.873c4.143,0,7.5-3.358,7.5-7.5 S319.579,260,315.437,260z"/>
    <path d="M315.437,300H232.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h82.937c4.143,0,7.5-3.358,7.5-7.5 S319.579,300,315.437,300z"/>
    <path d="M365.437,0H129.563c-0.251,0-0.501,0.013-0.749,0.038c-0.184,0.019-0.365,0.05-0.545,0.082
      c-0.06,0.011-0.122,0.016-0.182,0.028c-0.215,0.043-0.425,0.098-0.632,0.159c-0.025,0.008-0.052,0.012-0.077,0.02
      c-0.212,0.064-0.419,0.141-0.623,0.223c-0.022,0.009-0.044,0.015-0.066,0.024c-0.195,0.081-0.383,0.172-0.569,0.269
      c-0.031,0.016-0.064,0.029-0.094,0.045c-0.173,0.093-0.339,0.196-0.504,0.301c-0.042,0.027-0.086,0.049-0.127,0.077
      c-0.154,0.103-0.3,0.216-0.446,0.33c-0.048,0.038-0.098,0.07-0.145,0.109c-0.173,0.142-0.338,0.293-0.498,0.451
      c-0.015,0.015-0.031,0.027-0.046,0.042l-70,70c-0.018,0.018-0.033,0.039-0.051,0.057c-0.153,0.156-0.301,0.317-0.44,0.486
      c-0.044,0.054-0.082,0.112-0.125,0.167c-0.108,0.138-0.215,0.277-0.313,0.423c-0.033,0.049-0.06,0.101-0.092,0.151
      c-0.1,0.157-0.199,0.315-0.287,0.48c-0.02,0.037-0.035,0.075-0.054,0.112c-0.093,0.181-0.182,0.363-0.261,0.552
      c-0.011,0.026-0.019,0.054-0.03,0.081c-0.08,0.2-0.155,0.402-0.218,0.61c-0.008,0.026-0.013,0.053-0.021,0.08
      c-0.061,0.207-0.116,0.416-0.158,0.63c-0.012,0.059-0.017,0.119-0.027,0.178c-0.032,0.182-0.064,0.363-0.082,0.549
      c-0.025,0.248-0.038,0.498-0.038,0.749v380c0,4.142,3.357,7.5,7.5,7.5h305.873c4.143,0,7.5-3.358,7.5-7.5V7.5
      C372.937,3.358,369.579,0,365.437,0z M122.063,25.606V70H77.67L122.063,25.606z M357.937,450H67.063V85h62.5
      c4.143,0,7.5-3.358,7.5-7.5V15h220.873V450z"/>
    <path d="M159.563,85h155.873c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H159.563c-4.143,0-7.5,3.358-7.5,7.5 S155.421,85,159.563,85z"/>
    <path d="M315.437,180H232.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h82.937c4.143,0,7.5-3.358,7.5-7.5 S319.579,180,315.437,180z"/>
    <path d="M315.437,220H232.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h82.937c4.143,0,7.5-3.358,7.5-7.5 S319.579,220,315.437,220z"/>
    <path d="M192.5,315c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-82.937c-4.143,0-7.5,3.358-7.5,7.5v80 c0,4.142,3.357,7.5,7.5,7.5H192.5c4.143,0,7.5-3.358,7.5-7.5v-50c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5V380h-67.937v-65 H192.5z"/>
    <path d="M109.563,235H192.5c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-82.937c-4.143,0-7.5,3.358-7.5,7.5 S105.421,235,109.563,235z"/>
    <path d="M265.437,380H232.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h32.937c4.143,0,7.5-3.358,7.5-7.5 S269.579,380,265.437,380z"/>
    <path d="M315.437,340H232.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h82.937c4.143,0,7.5-3.358,7.5-7.5 S319.579,340,315.437,340z"/>
    <path d="M109.563,195H192.5c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-82.937c-4.143,0-7.5,3.358-7.5,7.5 S105.421,195,109.563,195z"/>
  </svg>
)
